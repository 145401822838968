/** Components */
import './component/footer';
// import './component/toTop';
import './component/navigation';

/** Page Scripts */
import pageRunner from './page';

pageRunner();

// Initiate WOW
new WOW().init();