/**
 * Page: Intro
 * Selector: .page-intro
*/

export default {
    selector: '.page-intro',
    init: init
}

function init() {

    // Auto-redirect to home page
    $(document).ready(function() {
        if ($('main').hasClass('intro-cn')) {
            window.setTimeout(function(){window.location.href = "/home_cn.html"},4000);
        } else {
            window.setTimeout(function(){window.location.href = "/home.html"},4000);
        }
    });

}