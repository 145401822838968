/**
 * Page: Register
 * Selector: .page-register
*/

export default {
    selector: '.page-register',
    init: init
}

function init() {

    $(document).ready(function(){
        
        //auto-fill mobile country code based on selected country
        $("[name='country']").on("change", function () {
            $("[name='mobile']").val($(this).find("option:selected").data("countrycode"));
        });

        $("input[name='referredby']").click(function () {
            if ($('#referredby8').is(':checked')) {
                //remove disabled to specify textbox only when Others is selected
                $('input[name="otherSpecify"]').removeAttr('disabled');

                //add required to specify textbox only when Others is selected
                $('input[name="otherSpecify"]').attr('required', 'required');
            } else {
                //remove required to specify textbox when Others is not selected
                $('input[name="otherSpecify"]').removeAttr('required');

                //add disabled to specify textbox when Others is not selected
                $('input[name="otherSpecify"]').attr('disabled', 'true');
            }
        });

        $('input[name="agent"]').click(function() {
            if ($('input[name="agent"]').is(':checked')) {
                $('#renNo').removeClass('d-none');
                $('input[name="renno"]').attr('required', 'required');
            } else {
                $('#renNo').addClass('d-none');
                $('input[name="renno"]').removeAttr('required');
            }
        });

        $('#registerform').submit(function(event) {
            if ($('#honeypot').val().length !== 0) {
                console.log('catch');
                event.preventDefault();
            }

            if (grecaptcha.getResponse() == ""){
                // fail validate
                alert("Please validate you are not a robot");
                event.preventDefault();
            }
        })


    });

}

// NEXPLATFORM MICROSITE INTEGRATION
// jQuery.noConflict();
$('#registerform').submit(function (event) {
	let url = 'https://1pavilionlead.nexplatform.my/api/v1/leads/regleadproc';
    const registerAgentUrl = 'https://1pavilionlead.nexplatform.my/api/v1/leads/createexternalagent';
	let input = {
		Country: $('input[name=country]').find('option:selected').val(),
        Salutation: $('input[name=salutation]').find('option:selected').val(),
		Name: $('input[name=firstname]').val(),//important
		ContactNo: $('input[name=mobile]').val(),//important
		Email: $('input[name=email]').val(), //important
		// PhoneCode: $('input[name=phoneCode]:checked').val(), //if any
		Projects: '2',
		Source: '30',
        SourceType: $('input[name=referredby]:checked').val(),
        SourceTypeOther: $('input[name=otherSpecify]').val(),

		/*Optional Fields*/
		Remark: $('input[name=preferred_size]:checked').val(),
		// Location: $('input[name=location]').val()
	};
    
	let chkboxAgent = $('input[name=agent]')[0];
	if (chkboxAgent.checked) {
		input.RenNum = $('input[name=renno]').val(); //important, required
		url = registerAgentUrl; // use different url when it is an Agent
	}

	// check if the form all good, then only post
    $.post(url, input)
        .done(function (response) {
            //handling after success
            // alert('success');
            // console.log('success');
            // console.log(response);
            // console.log(url);
            console.log(input);
            console.log(input.SourceType);
            console.log(input.SourceTypeOther);
            console.log(input.Remark);
        });
});