/** Script for navigation menu */
const navMenu = document.getElementById('mainMenu');
const navOpen = document.getElementById('menuButton');
const navClose = document.getElementById('menuClose');

if (navMenu !== null) {
    navOpen.onclick = function openNav() {
        document.getElementById("myNav").style.height = "100%";
    }
    
    navClose.onclick = function closeNav() {
        document.getElementById("myNav").style.height = "0%";
    }
}