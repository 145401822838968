// import index from './main';
import intro from './intro';
import home from './home';
import location from './location';
import floorplan from './floorplan';
import gallery from './gallery';
import register from './register';

const pages = [
    // index,
    intro,
    home,
    location,
    floorplan,
    gallery,
    register
]

// Page run JS based on page #{id}
function pageRunner() {
    pages.forEach( page => {
        const el = document.querySelector(page.selector);

        if (el !== null) {
            page.init(el);
        }
    })
}

export default pageRunner;
export { pages };