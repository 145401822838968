/**
 * Page: Gallery
 * Selector: .page-gallery
*/

export default {
    selector: '.page-gallery',
    init: init
}

function init() {

    // Header carousel auto slide
    $('.carousel').carousel({
        interval: 5000 //changes the speed
    })

}