/**
 * Page: Home
 * Selector: .page-home
*/

export default {
    selector: '.page-home',
    init: init
}

function init() {

    // Header carousel auto slide
    $('.carousel').carousel({
        interval: 5000 //changes the speed
    })

}